import './App.css';
import MainApp from './MainApp';
import Home from './Home';
import CustomSnackbar from "./components/CustomSnackbar";
import LogoutSwitch from './components/LogoutSwitch';
import React, { useState, useEffect } from 'react';
import SocketContext from './socket-context'
import { CircularProgress } from '@mui/material';
import SearchBar from './components/searchbar/SearchBar';
import { storage, initiateSocket, disconnectSocket, subscribeToConnect, 
  subscribeToInit, subscribeToTokenExpired, subscribeToAppConnection, 
  subscribeToDisconnect, subscribeToConnectFailed, subscribeToAndroidAppVersion,
  EVENT_WEB_CLIENT_DISCONNECT, EVENT_WEB_SEARCH, TOKEN, WEBSOCKET_EMIT_PREFIX } 
  from "./socket/socket";
let socket;
let searchTimeoutFunc;
let searchTimeout = 15000;
const defaultSeverity = "success";
const defaultMsg = "success";

function App() {

  const [connected, setConnected] = useState(false);
  const [token, setToken] = useState('');
  const [idle, setIdle] = useState(false);
  const [snackBar, setSnackBar] = useState({show: false, severity: defaultSeverity, message: defaultMsg});
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(true)
  const [showSearchProgress, setShowSearchProgress] = useState(false)


function reloadQR(){
    setToken('');
    setIdle(false);
    if(socket){
      socket.connect();
    }else {
      socket = initiateSocket();
    }
    window.newrelic.addPageAction('reloadQR');
}

const handleSnackBarClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackBar({show: false, severity: defaultSeverity, message : defaultMsg})
};

const onLogout = () => {
  if (!socket) return(true);
  socket.emit(EVENT_WEB_CLIENT_DISCONNECT, token);
  window.newrelic.addPageAction(WEBSOCKET_EMIT_PREFIX + EVENT_WEB_CLIENT_DISCONNECT, {socketId : socket.id, token : token});
  window.newrelic.addPageAction('logout',{socketId: socket.id});
}

const handleSearch = () => {
  if (!socket) return(true);
  onSearch();
  let token = storage.getItem(TOKEN);
  socket.emit(EVENT_WEB_SEARCH, {token, searchTerm});
  window.newrelic.addPageAction(WEBSOCKET_EMIT_PREFIX + EVENT_WEB_SEARCH, {socketId : socket.id, searchTerm : searchTerm});
  window.newrelic.addPageAction('webSearch',{searchTerm});
}

const onSearch = () => {
  setShowSearchProgress(true);
  searchTimeoutFunc = setTimeout(() => {
      onFailedResults();
  }, searchTimeout);
}

const onResults = () => {
  clearTimeout(searchTimeoutFunc);
  setShowSearchProgress(false);
}

const onFailedResults = () => {
  setShowSearchProgress(false);
  setSnackBar({show: true, severity: "error", message : "No Results Found!"})
}

useEffect(() => {

  if(!socket){
    socket = initiateSocket();
  }

  subscribeToConnect(socket);
  subscribeToConnectFailed(socket, setIdle);
  subscribeToInit(socket, setToken, setIdle);
  subscribeToTokenExpired(socket, setIdle);
  subscribeToAppConnection(socket, setConnected);
  subscribeToAndroidAppVersion(socket, setShowSearchBar, setSearchTerm);
  subscribeToDisconnect(socket, setConnected);

  return () => {
    disconnectSocket(socket);
    }
}, []);


  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <header className="App-header">
          <h1 className="app-name"> Torrz Web </h1>
          {connected ? (
            <div>
              {showSearchBar ? (
                <div className="searchBar">
                  <div className="inline-block search">
                    <SearchBar
                      value={searchTerm}
                      onChange={(newValue) => setSearchTerm(newValue)}
                      onRequestSearch={() => handleSearch()}
                    />
                  </div>
                  {showSearchProgress ? (
                    <CircularProgress
                      style={{ color: "#ec415a" }}
                      size={30}
                      className="inline-block search-progress"
                    />
                  ) : null}
                </div>
              ) : null}

              <LogoutSwitch onLogout={onLogout} />
              <MainApp onResults={onResults} />
              <CustomSnackbar
                open={snackBar.show}
                onClose={handleSnackBarClose}
                severity={snackBar.severity}
                message={snackBar.message}
              />
            </div>
          ) : (
            <Home token={token} reloadQR={reloadQR} idle={idle} />
          )}
        </header>
      </div>
    </SocketContext.Provider>
  );
}

export default App;
