import { React } from "react";
import { Snackbar, Alert } from '@mui/material';

export default function CustomSnackbar(props) {
  return (
    <div>
    <Snackbar open={props.open} autoHideDuration={3000} onClose={props.onClose}>
       <Alert elevation={6} variant="filled" {...props} >
          {props.message}
        </Alert>
    </Snackbar>
    </div>
  );
}
