import { React } from "react";
import reload_qr from "../assets/reload_qr.png";
import { IconButton, CircularProgress } from '@mui/material';

export default function QRImage(props) {
    function reloadQR() {
        props.reloadQR();
      }

  return (
    <div id="qr_reload">
      {props.token === "" && !props.idle ? (
        <CircularProgress color="secondary" size={"4rem"} thickness={4} />
      ) : null}
      {props.idle ? (
        <IconButton onClick={() => reloadQR()}>
          <img 
            id="qr_reload_img"
            alt="reload qr"
            src={reload_qr} />
        </IconButton>
      ) : null}
    </div>
  );
}
