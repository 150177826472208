import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, TablePagination } from '@mui/material';
import { loadCSS } from 'fg-loadcss';
import SocketContext from './socket-context'
import ResultTable from "./components/ResultTable";
import CustomSnackbar from "./components/CustomSnackbar";
import { EVENT_TORRENT_RESULTS, WEBSOCKET_PREFIX } from './socket/socket';
import WelcomeScreen from './components/WelcomeScreen';


function createData(index, torrent) {
  return { index, name:torrent.name, 
    seeder: torrent.seeders, leecher:torrent.leechers, 
    size: torrent.size, site:torrent.site, 
    magnet: torrent.magnet, info:torrent.humanizedInfo, 
    link:torrent.link };
}


function Main(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState(null);
  const [open, setOpen] = useState(false);
  const [resultsUpdated, setResultsUpdated] = useState(false);

  useEffect(() => {
    if (props.socket) {
      props.socket.on(EVENT_TORRENT_RESULTS, (results) => {
        let torrents = JSON.parse(results.torrents);
        if (torrents !== null) {
          let filteredResults = [];
          torrents.forEach((ele, index) => {
            filteredResults.push(createData(index + 1, ele));
          });
          setResultsUpdated(true);
          setRows(filteredResults);
          window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_TORRENT_RESULTS, {socketId : props.socket.id, resultSize: torrents.length});
        }
        props.onResults();
      });
    }
    
    const node = loadCSS(
        'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
        document.querySelector('#font-awesome-css'),
      );
  
      return () => {
        node.parentNode.removeChild(node);
      };

  }, [props.socket])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setResultsUpdated(false);
    setOpen(false);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMagnetLink = (event, magnet) => {
      event.preventDefault();
      // console.log(magnet);
      copyToClipboard(magnet);
      setOpen(true);
      window.newrelic.addPageAction('copyMagnet',{magnet: magnet});
  };

  const handleBrowserLink = (link) => {
   window.open(link);
   window.newrelic.addPageAction('openLink',{link: link});
  };

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      // console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
}


  return (
    <Paper className="main">
      <TableContainer className="table_container">
        {rows ? (
          <div>
            <ResultTable
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              handleMagnetLink={handleMagnetLink}
              handleBrowserLink={handleBrowserLink}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <WelcomeScreen />
        )}
      </TableContainer>

      <div>
        <CustomSnackbar
          open={open}
          onClose={handleClose}
          severity="success"
          message="Magnet Link copied to ClipBoard!"
        />

        <CustomSnackbar
          open={resultsUpdated}
          onClose={handleClose}
          severity="success"
          message="Results Updated!"
        />
      </div>
    </Paper>
  );
}

const MainApp = props => (
  <SocketContext.Consumer>
  {socket => <Main {...props} socket={socket} />}
  </SocketContext.Consumer>
)

export default MainApp;