import { io } from 'socket.io-client';
const parser = require('ua-parser-js');
const config =  require('../config/config');
const storage = localStorage;

const WEBSOCKET_PREFIX = '/websocket/';
const WEBSOCKET_EMIT_PREFIX = '/websocket/emit/';

const EVENT_CONNECT = 'connect';
const EVENT_DISCONNECT = 'disconnect';
const EVENT_RECONNECT_FAILED = 'reconnect_failed';
const EVENT_INIT = 'init';
const EVENT_RESUME = 'resume';
const EVENT_TOKEN_EXPIRED = 'token-expired';
const EVENT_CONNECTED_TO_APP = 'connected-to-app';
const EVENT_WEB_CLIENT_DISCONNECT = 'web-client-disconnect';
const EVENT_TORRENT_RESULTS = 'torrent-results';
const EVENT_WEB_SEARCH = 'web-search';
const EVENT_ANDROID_APP_VERSION = "android-app-version"

const TOKEN = 'token';
const CLIENT_DISCONNECT_REASON = 'io client disconnect';
const TRANSPORT_CLOSE = 'transport close';

const SEARCHBAR_MIN_APP_VERSION = 14;

let timeoutId;


const initiateSocket = () => {
    let socket = io(config.WS_ENDPOINT, {
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionAttempts: 2,
      reconnectionDelay: 500,
      reconnectionDelayMax: 2000,
      randomizationFactor: 0.5,
      timeout: 5000,
    });
    //console.log(`Connecting to server...`);
    return socket;
  }
  
  const disconnectSocket = (socket) => {
    //console.log('Disconnecting server...');
    if(socket) socket.disconnect();
  }
  
  const onConnect = (socket) => {
    //console.log('server connected');
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_CONNECT, {socketId : socket.id});
    let agent = parser(navigator.agent);
    let previousToken = storage.getItem(TOKEN);
      if(socket){
        if(previousToken){
          //console.log('resuming');
          socket.emit(EVENT_RESUME, previousToken);
          window.newrelic.addPageAction(WEBSOCKET_EMIT_PREFIX + EVENT_RESUME, {socketId : socket.id, token : previousToken});
        }else{
          socket.emit(EVENT_INIT, agent);
          window.newrelic.addPageAction(WEBSOCKET_EMIT_PREFIX + EVENT_INIT, {socketId : socket.id, agent : agent});
        }
      }
  }

  const subscribeToConnect = (socket) => {
    if (!socket) return(true);
    socket.on(EVENT_CONNECT, () => onConnect(socket));
  }

  const onConnectFailed = (socket, setIdle) => {
    //console.log('server connection failed');
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_RECONNECT_FAILED, {token: storage.getItem(TOKEN)});
    clearTimeout(timeoutId);
    setIdle(true);
    storage.removeItem(TOKEN);
  }

  const subscribeToConnectFailed = (socket, setIdle) => {
    if (!socket) return(true);
    socket.io.on(EVENT_RECONNECT_FAILED, () => onConnectFailed(socket, setIdle));
  }

  const onInit = (data,socket,setToken,setIdle) => {
    const intervalFunc = () => setTimeout(() => {
      //console.log("disconnecting on idle");
        if(socket!=null){
          socket.disconnect();
          setIdle(true);
        }
      }, data.ttl);
    timeoutId = intervalFunc();
    //console.log('init:',data);
    setToken(data.token);
    window.newrelic.setCustomAttribute('socketId',socket.id);
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_INIT, {socketId : socket.id, token: data.token, ttl: data.ttl});
  }
  
  const subscribeToInit = (socket,setToken,setIdle) => {
    if (!socket) return(true);
    socket.on(EVENT_INIT, (data) => onInit(data, socket, setToken, setIdle));
  }
  
  const subscribeToTokenExpired = (socket, setIdle) => {
    if (!socket) return(true);
        socket.on(EVENT_TOKEN_EXPIRED, () => {
          window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_TOKEN_EXPIRED, {socketId : socket.id});
          socket.disconnect();
          setIdle(true);
      });
  }
  
  const onConnectedToApp = (socket, data, setConnected) => {
    clearTimeout(timeoutId);
    //console.log('connected to app.');
    storage.setItem(TOKEN, data);
    setConnected(true);
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_CONNECTED_TO_APP, {socketId : socket.id, token: data});
  }

  const subscribeToAppConnection = (socket, setConnected) => {
    if (!socket) return(true);
    socket.on(EVENT_CONNECTED_TO_APP, (data) => onConnectedToApp(socket, data, setConnected));
  }

  const onAndroidAppVersion = (socket, data, setShowSearchBar, setSearchTerm) => {
    let appVersion = data.appVersion;
    //console.log('appVersion: ',appVersion);
    if (appVersion < SEARCHBAR_MIN_APP_VERSION) {
      setShowSearchBar(false);
    } else {
      setSearchTerm('');
      setShowSearchBar(true);
    }
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_ANDROID_APP_VERSION, {socketId : socket.id, appVersion: appVersion});
  }

  const subscribeToAndroidAppVersion = (socket, setShowSearchBar, setSearchTerm) => {
    if (!socket) return(true);
    socket.on(EVENT_ANDROID_APP_VERSION, (data) => onAndroidAppVersion(socket, data, setShowSearchBar, setSearchTerm));
  }

  const onDisconnect = (reason, socket, setConnected) => {
    window.newrelic.addPageAction(WEBSOCKET_PREFIX + EVENT_DISCONNECT, {socketId : socket.id, token: storage.getItem(TOKEN),reason: reason});
    clearTimeout(timeoutId);
    setConnected(false);
    if (reason !== TRANSPORT_CLOSE) {
      storage.removeItem(TOKEN);
    }
    //console.log("disconnected: ", reason);
    if (reason !== CLIENT_DISCONNECT_REASON) {
      //console.log("not client close");
      socket.connect();
    }
  }
  
  const subscribeToDisconnect = (socket, setConnected) => {
    if (!socket) return(true);
    socket.on(EVENT_DISCONNECT, (reason) => onDisconnect(reason, socket, setConnected));
  }


 export {storage, initiateSocket, disconnectSocket, 
    subscribeToConnect, subscribeToInit,
    subscribeToTokenExpired, subscribeToAppConnection,
    subscribeToDisconnect, subscribeToConnectFailed,
    subscribeToAndroidAppVersion,
    EVENT_WEB_CLIENT_DISCONNECT, EVENT_TORRENT_RESULTS,
    EVENT_WEB_SEARCH, TOKEN, WEBSOCKET_EMIT_PREFIX,
    WEBSOCKET_PREFIX
 }