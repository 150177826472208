import { React } from "react";
import MenuIcon from "./MenuIcon";

export default function Instructions() {
  return (
    <div id="instructions">
    <h4 className="heading">To use Torrz on your computer:</h4>
    <br />

    <ol type="1" >
      <li>
        <p> Open Torrz on your phone </p>
      </li>
      <li>
        <p>
          Tap <strong> Menu </strong>
          <MenuIcon /> and select <strong> Torrz Web</strong>
        </p>
      </li>
      <li>
        <p>Point your phone to this screen to capture the code</p>
      </li>
    </ol>
  </div>
  );
}
