import React from "react";
import QRImage from "./components/QRImage";
import QRReload from "./components/QRReload";
import "./App.css";

import Instructions from "./components/Instructions";

function HomePage(props) {

  return (
    <div className="home_root">
      <div className="home_container">
          <QRImage
            token={props.token}
          />
          <QRReload 
            token={props.token}
            idle={props.idle}
            reloadQR={props.reloadQR}
          />
            
        <Instructions />
      </div>
    </div>
  );
}

export default HomePage;
