import React, {useEffect} from "react";
import { Tooltip,Icon, IconButton } from '@mui/material';
import { loadCSS } from 'fg-loadcss';

function LogoutSwitch(props) {
    
    useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
          );
      
          return () => {
            node.parentNode.removeChild(node);
          };
      }, [])


    return(
        <div id="logout">
            <Tooltip title="Disconnect">
            <IconButton style={{color:'white'}} onClick={props.onLogout}>
                <Icon  
                className="fa fa-sign-out-alt" 
                fontSize={window.innerWidth <= 500 ? "medium" :"large" }
                 />
            </IconButton>
            </Tooltip>
        </div>
    )
}

export default LogoutSwitch;