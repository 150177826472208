import { React } from "react";

export default function WelcomeScreen(props) {

  return (
    <div className="initial">
    <h4>Search for torrents in the app 
      and {<br/>}
      get the results here as well!
    </h4>
 </div>
  );
}
