import { React, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../assets/logo.png";

export default function QRImage(props) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {

    window.addEventListener("resize", handleResize);

    return(() => {
      window.removeEventListener("resize", handleResize);
    })
  })

  return (
    <div id="qr">
          {props.token !== "" ? (
            <QRCode
              value={props.token}
              size={windowWidth < 768 ? 192 : windowWidth < 1250 ? 224 : 256}
              fgColor={"#606060"}
              logoOpacity={1}
              logoImage={logo}
            />
          ) : null}
        </div>
  );
}
