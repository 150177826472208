import { React } from "react";
import {
  IconButton, Icon, Table,
  TableBody, TableHead, TableRow, TableCell
} from '@mui/material';

const columns = [
  { id: 'index', label: 'Sl No.', minWidth: 40 , align : 'center'},
{ id: 'name', label: 'Name', minWidth: 175 },
{ id: 'seeder', label: 'Seeder', minWidth: 75, align : 'center' },
{
  id: 'leecher',
  label: 'Leecher',
  minWidth: 75,
  align : 'center'
},
{
  id: 'size',
  label: 'Size',
  minWidth: 75,
  align : 'center'
},
{
  id: 'info',
  label: 'Added',
  minWidth: 100,

},
{
  id: 'site',
  label: 'Source',
  minWidth: 100,

},
];


export default function ResultTable(props) {

  return (
    <Table stickyHeader aria-label="Torrents">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                  key='magnet'
                  style={{ minWidth: 75 }}
                >
                  Magnet
                </TableCell>
                <TableCell
                  key='link'
                  style={{ minWidth: 75 }}
                >
                  Link
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={"magnet"}>
                    <a href={row["magnet"]} style={{ textDecoration: "none" }}>
                      <IconButton
                        onClick={(event) => props.handleMagnetLink(event, row["magnet"])}
                        style={{ maxHeight: 40 }}
                      >
                        <Icon fontSize="default" className="fa fa-magnet" />
                      </IconButton>
                    </a>
                  </TableCell>
                  <TableCell key={"link"}>
                    <IconButton
                      onClick={() => props.handleBrowserLink(row["link"])}
                      style={{ maxHeight: 40 }}
                    >
                      <Icon
                        fontSize="small"
                        className="fa fa-external-link-alt"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
)}



